//=include polyfills/Object.assign.js
//=include polyfills/closest.js
//=include polyfills/CustomEvent.js

//=require slick-carousel/slick/slick.js

//=include plugins/country-selection.js
//=include plugins/scroll-table.js
//=require plugins/multi-select.js
//=include plugins/quantity-control.js
//=include plugins/search.js
//=include plugins/move.js
//=include plugins/scenes.js
//=include plugins/sliding-accordion.js
//=require plugins/carousel.js
//=require plugins/fancy-grid.js
//=require plugins/popup-page.js
//=require plugins/video-block.js
//=require plugins/extraSlickOptions.js

//=include components/quantity-control.js
//=include components/multi-select.js
//=include components/search.js
//=include components/move.js
//=include components/scenes.js
//=include components/sliding-accordion.js
//=require components/carousel.js
//=require components/fancy-grid.js
//=require components/popup-page.js
//=require components/video-block.js
//=require components/hotspot.js

//=include aos/dist/aos.js

//=include tools/loop.js
//=include tools/svg.js
//=include tools/breakpoint.js

Astro.quantityControl();
Astro.multiSelect();
Astro.search();
Astro.move();
Astro.scenes();
Astro.slidingAccordions();
Astro.carousel();
Astro.fancyGrid();
Astro.popupPage();
Astro.video();
Astro.hotspotGallery();
Astro.hotspotGalleryTooltips();
Astro.hotspotGalleryProducts();

AOS.init({
    once: true,
    offset: 0
});

$.extend($.easing, {
    easeInQuint: function (x) {
        return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
        return 1 - Math.pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
        return x < 0.5 ?
            16 * x * x * x * x * x :
            1 - Math.pow( -2 * x + 2, 5 ) / 2;
    }
});

$.extend({
    getParams: function(qs) {
        var params = {};

        if (qs === undefined) {
            qs = window.location.search;
        }

        if (qs) {
            var pairs = qs.replace('?', '').split('&');

            for (var i=0; i<pairs.length; i++) {
                var pair = pairs[i].split('=');
                var k = pair[0];
                var v = decodeURIComponent(pair[1]);

                if (params[k]) {
                    params[k] += ',' + v;
                } else {
                    params[k] = v;
                }
            }
        }

        return params;
    }
});


var ajax_response = 0;

// function updateHeaderShadowState(force) {
//     if (999 > $(window).width()) {
//         return false;
//     }

//     if (!force) {
//         $('.header').removeClass('header--force');
//     }
// }

function searchEvent(location, value)
{
    var intRegex = /^\d+$/;
    var isInt = intRegex.test(value) ? true : false;
    var action;

    if (isInt && value.length == 7) {
        action = '7 digit';
    } else if (isInt && value.length == 4) {
        action = '4 digit';
    } else if (isInt) {
        action = 'digit - other';
    } else {
        action = 'other';
    }

    TRACKING.trackEvent(location, action, value);
}

$(function() {
    // Polyfill input placeholders
    // $('input[placeholder]').cleverlabels();

    // Replace selects with fancy selects
    // replaceSelects();

    // Prevent hash links from jumping to top
    $("a[href='#']").click(function(e) {
        e.preventDefault();
    });

    // Remove header transitions for initial render
    // $('.header').addClass('header--force');

    // // Set initial header state
    // updateHeaderShadowState(true);

    // // Add header shadow on scroll
    // $(window).scroll(function() {
    //     updateHeaderShadowState(false);
    // });

    // Enable locale switcher
    $('select[name="locale"]').on('change', function() {
        window.location.href = $(this).val();
    });

    $('select.js-select-navigation').on('change', function() {
        window.location.href = $(this).val();
    });

    // Some dodgy selectors, but we need to keep it dynamic in case there
    // are multiple instances of tabbed content on a page
    // window.switchTab = function(tabId, scrollTo, highlight) {
    //         var nav = $('.tabs a[href="' + tabId + '"]').parents('.tabs'),
    //             tab = $(tabId);

    //         highlight = typeof highlight == 'undefined'
    //             ? false
    //             : true;

    //         if (nav.length) {
    //             nav.find('.tabs__link').removeClass('tabs__link--active');
    //             nav.find('[href="' + tabId + '"]').addClass('tabs__link--active');

    //             if (tab.parents('.bg-icon').length) {
    //                 tab.parents('.bg-icon')
    //                     .toggleClass('bg-icon--hidden', typeof nav.find('[href="' + tabId + '"]').data('show-bg-icon') === 'undefined');
    //             }
    //         }

    //         tab.parent().find('.tabs__content').removeClass('tabs__content--active');
    //         tab.addClass('tabs__content--active');

    //         if (typeof scrollTo === 'boolean' && scrollTo) {
    //             $('body, html')
    //                 .animate({
    //                     scrollTop: tab.parents('.section:first').offset().top - $('.header-buffer').outerHeight()
    //                 }, 1000, 'easeOutQuint');
    //         }

    //         $(window).trigger('resize');
    //     };

    // $(document).on('click', '.tabs__link', function(e) {
    //     e.preventDefault();
    //     switchTab($(this).attr('href'));
    // });

    // $(document).on('click', '[data-switch-tab]', function(e) {
    //     e.preventDefault();
    //     switchTab($(this).attr('href'), true, $(this).data('highlight-tab'));
    // });

    // $('.tabs .tabs__link:first-child')
    //     .each(function(i, el) {
    //         window.switchTab(el.hash);
    //     });

    // Login button
    $('[data-action="login"], [data-action="open-login-modal"]').on('click', function(event) {
        event.preventDefault();

        if ($(this).attr('data-restricted')) {
            $('.login-modal').addClass('login-modal--restricted');
        }

        // Close forgot-password modal
        $('body').removeClass('body--forgot-password-modal');
        $('.forgot-password-modal').removeClass('forgot-password-modal--activating forgot-password-modal--active');

        // Open login modal
        $('body').addClass('body--login-modal');

        $('.login-modal').addClass('login-modal--activating');

        setTimeout(function() {
            $('.login-modal').addClass('login-modal--active');
            // Rerun dummy labels to fix dimensions
            $('.login-modal .cleverpassword .input[name="password"]').appendTo($('.login-modal .field.password'));
            $('.login-modal .cleverpassword').remove();
            // $('.login-modal input[name="password"]').cleverlabels();

            $('.login-modal input[name="email"]').focus();
        }, 1);
    });

    // Login modal close
    $('[data-action="close-login-modal"]').on('click', function(event) {
        event.preventDefault();
        $('body').removeClass('body--login-modal');
        $('.login-modal').removeClass('login-modal--active');
        setTimeout(function() {
            $('.login-modal').removeClass('login-modal--activating');
        }, 300);
    });

    // Password modal close
    $('[data-action="close-forgot-password-modal"]').on('click', function(event) {
        event.preventDefault();
        $('body').removeClass('body--forgot-password-modal');
        $('.forgot-password-modal').removeClass('forgot-password-modal--active');
        setTimeout(function() {
            $('.forgot-password-modal').removeClass('forgot-password-modal--activating');
        }, 300);
    });

    // Forgot password button
    $('[data-action="forgot-password"], [data-action="open-forgot-password-modal"]').on('click', function(event) {
        event.preventDefault();

        // Close login modal
        $('body').removeClass('body--login-modal');
        $('.login-modal').removeClass('login-modal--activating login-modal--active');

        // Hide/Remove message if we are re-opening this modal
        $('.forgot-password-modal__form-message').html('').hide()

        // Open forgot-password modal
        $('body').addClass('body--forgot-password-modal');

        $('.forgot-password-modal').addClass('forgot-password-modal--activating');

        setTimeout(function() {
            $('.forgot-password-modal').addClass('forgot-password-modal--active');
            $('.forgot-password-modal input[name="email"]').focus();
        }, 1);
    });

    $('.login-modal__form').on('submit', function(e) {
        e.preventDefault();

        var $submit = $(this).find(':submit');
        $submit.addClass('button--disabled').attr('disabled', true);

        $.ajax({
            url: $(this).attr('action'),
            method: "POST",
            data: $(this).serializeArray()
        }).done(function(data) {
            if (data.result == 'Error') {
                $("input[name='password']").val('');
                $('.login-modal__error').html(data.message).fadeIn(300);
            } else {
                if (data.payload.redirect !== undefined) {
                    window.location.assign(data.payload.redirect);
                    return;
                }
            }
            $submit.removeClass('button--disabled').attr('disabled', false);
        });

        return false;
    });

    $('.forgot-password-modal__form').on('submit', function(e) {
        e.preventDefault();

        var $submit = $(this).find(':submit');
        $submit.addClass('button--disabled').attr('disabled', true);

        $.ajax({
            url: $(this).attr('action'),
            method: "POST",
            data: $(this).serializeArray()
        }).done(function(data) {
            if (data.payload.message !== undefined) {
                $("input[name='email']").val('');
                $('.forgot-password-modal__form-message').html(data.payload.message).fadeIn(300);
            }
            $submit.removeClass('button--disabled').attr('disabled', false);
        });

        return false;
    });

    // Pricing switcher
    $('[data-action="switch-pricing"]').on('click', function(event) {
        event.preventDefault();

        $(this).html('<i class="fa fa-refresh fa-spin"></i>');

        $.get('/store/switch')
            .done(function(data) {
                location.reload();
            });
    });

    // Go back links
    $('[data-action="go-back"]').on('click', function(event) {
        event.preventDefault();
        window.history.back();
    });

    // Quick add loading icon
    $('.quick-add-form').on('submit', function() {
        $(this).find('button[type="submit"] span').html('<i class="fa fa-circle-o-notch fa-spin"></i>');
    });

    // Enable confirm buttons
    $('a[data-confirm]').on('click', function (event) {
        event.preventDefault();

        var trigger = $(this),
            options = {
                no: $('#js-confirm-popup__action--no'),
                yes: $('#js-confirm-popup__action--yes')
            },
            content = $('#confirm-popup');

        $.magnificPopup.open({
            type: 'inline',
            mainClass: 'mfp--outside mfp--confirm',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
            closeOnBgClick: false,
            items: {
                src: '.js-confirm-popup'
            },
            callbacks: {
                beforeOpen: function () {
                    content.find('.confirm-popup__title').text(trigger.data('confirm'));
                    options.yes.attr('href', trigger.attr('href'));
                },
                open: function () {
                    options.no.on('click', function (e) {
                        e.preventDefault();
                        $.magnificPopup.close();
                    });
                }
            },
            fixedContentPos: true
        });
    });

    $('.js-download-action-add').click(function(e) {
        e.preventDefault();
        var current = $(this),
            url = $(this).attr('href');

        // Horrible hack to remove the word advertising from the URL so Adblock doesn't block it
        if (current.hasClass('js-download-action-add--bundle')) {
            url = url.replace('advertising', 'ahash');
        }

        var extra = '';

        if ($("#partner-sub-filter_replace").length) {
            extra = '&sub=' + $("#partner-sub-filter").val();
        }

        if (url) {
            xhr = $.ajax(url + '?v=' + (+new Date) + extra, {
                success: function (data) {
                    if (current.hasClass('js-download-action-add--all')) {
                        $('.product-dl__list .js-download-action-add .media-list__action-label').text(globalTranslations.downloadAdded);
                        $('.product-dl__list .js-download-action-add').addClass('button--disabled');
                    }

                    // $('.download-action__status', current).removeClass('fa-plus').addClass('fa-check');
                    if ($('.media-list__action-label', current).text() == globalTranslations.addToDownload) {
                        $('.media-list__action-label', current).text(globalTranslations.downloadAdded);
                        current.addClass('button--disabled');
                    } else if ($('.media-list__action-label', current).text() == globalTranslations.addAllToDownload) {
                        $('.media-list__action-label', current).text(globalTranslations.allDownloadsAdded);
                        current.addClass('button--disabled');
                    }
                    current
                        .attr('href', '')
                        .css('cursor', 'default');

                    // Sort out child files if it's a bundle - main and popup add folder
                    // if (current.hasClass('js-download-action-add--bundle')) {
                    //     current
                    //         .parents('.product-dl-list__detail:first, .bundle-items__body:first')
                    //         .find('.js-download-action-add .download-action__status')
                    //         .removeClass('fa-plus')
                    //         .addClass('fa-check')
                    //         .parents('.download-action')
                    //         .attr('href', '')
                    //         .css('cursor', 'default')
                    //         .find('.download-action__label')
                    //         .text(globalTranslations.downloadAdded);
                    // }
                    // var itemsContainer = current.parents('.bundle-items:first');
                    // Sort out bundle if it's a child file of a bundle - Popup
                    // if (itemsContainer.length) {
                    //     var items = itemsContainer.find('.bundle-items__item .js-download-action-add');
                    //
                    //     if (items.length == items.find('.fa-check').length) {
                    //         itemsContainer
                    //             .find('.js-download-action-add--bundle .download-action__status')
                    //             .removeClass('fa-plus')
                    //             .addClass('fa-check')
                    //             .parents('.download-action')
                    //             .attr('href', '')
                    //             .css('cursor', 'default')
                    //             .find('.download-action__label')
                    //             .text(globalTranslations.downloadAdded);
                    //     }
                    // }
                    $('.page-intro__basket_count').html(data.result.count);
                },
                dataType: 'json'
            });
        }
    });

    // Close notice
    $('a[data-action="close-notice"]').on('click', function(e) {
        e.preventDefault();

        $('body').removeClass('has-notice-bar');
        $.get('/index/clear-notice-bar');
    });

    // Stock rollout
    $('.product-stock__togglemore').on('click', function() {
        if ($(this).hasClass('product-stock__togglemore--inline')) {
            var $parent = $(this).closest('.product-stock');
            $parent.addClass('product-stock--expanded');
            $(this).remove();
        } else {
            var $link = $('.product-stock__togglemore');
            if (!$('.product-stock__rollout').hasClass('product-stock__rollout--hidden')) {
                $('.product-stock__rollout').hide().addClass('product-stock__rollout--hidden');
                $link.text($link.data('labelmore'));
                return;
            }
            $('.product-stock__rollout').show().removeClass('product-stock__rollout--hidden');
            $link.text($link.data('labelless'));
        }
    });
});

var tippyConfig = {
    animation: 'fade',
    arrow: true,
    theme: 'astro',
    interactive: true
};

// Tooltips
tippy('[data-tooltip]', tippyConfig);

$(document)
    .on('click', '[data-close-tooltip]', function(e) {
        var popper = $(this).parents('.tippy-popper:first');

        if (popper.length) {
            popper[0]._tippy.hide(0);
        }
    });

window.addEventListener('scroll', function() {
    $.each(document.querySelectorAll('.tippy-popper'), function (i, v) {
        var instance = v._tippy

        if (instance && instance.state.visible) {
            instance.popperInstance.disableEventListeners()
            instance.hide()
        }
    });
});

$('[data-action="submit-search"]')
    .on('click', function(e) {
         e.preventDefault();
         $(this).parents('form:first').trigger('submit');
    });

$('body')
    .on('click', '[data-ga-category]', function(e) {
        var link = $(this);
        var callback = $.noop;

        if (link.is('a')
            && link.attr('target') != '_blank'
            && link.attr('href')
            && link.attr('href').charAt(0) != "#"
            && typeof link.data('ga-no-redirect') === 'undefined'
        ){
            e.preventDefault();
            callback = function() {
                window.location.href = link.attr('href');
            }
        }

        TRACKING.trackEvent(link.data('ga-category'), link.data('ga-action') || 'Click', link.data('ga-label') || link.attr('href'), 0, callback);
    });

var downloadsList = $('#js-downloads-list'),
    downloadsListToggle = $('#js-toggle-downloads');

downloadsListToggle
    .on('click', function(e) {
        e.preventDefault();
        downloadsList.toggleClass('downloads-list--closed');
        downloadsListToggle.remove();
    });

// var mobileNavOpenClass = 'header-nav__item--open';

var navToggle = $('.js-toggle-nav');

navToggle.on('click', function() {
    var target = $(this).data('target');
    $('.' + target).toggleClass(target + '--active');
});

var subNavToggle = $('.js-toggle-sub-nav');

subNavToggle.on('click', function() {
    if (window.matchMedia('(max-width: 1023px)').matches) {
        $(this).toggleClass('account-header__nav__item--hover');
    }
});

subNavToggle.on('mouseenter', function() {
    if (!window.matchMedia('(max-width: 1023px)').matches) {
        $(this).addClass('account-header__nav__item--hover');
    }
});

subNavToggle.on('mouseleave', function() {
    if (!window.matchMedia('(max-width: 1023px)').matches) {
        $(this).removeClass('account-header__nav__item--hover');
    }
});

// Need to target it explicilty for iOS
// $(document)
//     .on('click', '.header-nav--mobile .header-nav__item--has-sub-nav .header-nav__item__arrow', function(e) {
//         e.preventDefault();

//         var link = $(this),
//             navItem = link.parents('.header-nav__item:first');

//         navItem.toggleClass(mobileNavOpenClass);
//     });

// Stop the animation if the user scrolls. Defaults on .stop() should be fine
$('body, html')
    .bind('scroll mousedown DOMMouseScroll mousewheel keyup', function(e) {
        if (e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
            $('body, html').stop();
        }
    });

$(document)
    .on('click', '[data-listing-switch]', function(e) {
        var link = $(this),
            styleId;

        if (typeof link.data('listing-scroll') !== 'undefined') {
            styleId = link.parents('[data-id]:first').data('id');
        }

        switchListingType(
            link.data('listing-switch'),
            styleId,
            styleId
                ? false
                : true
        );
    });

var switchListingType = function(type, styleId, skipSave) {
        var currentType = document.querySelector('html')
                .getAttribute('data-listing-type'),
            otherContainer;

        if (styleId && !skipSave && typeof window.history.pushState !== 'undefined') {
            // Save an initial state if we don't have one
            // So if a user clicks back, it can scroll to where they first clicked
            if (!window.history.state || !window.history.state.isFirst) {
                window.history.pushState({view: currentType, styleId: styleId, isFirst: true}, '');
            } else if (window.history.state && window.history.state.isFirst) {
                window.history.replaceState({view: currentType, styleId: styleId, isFirst: true}, '');
            }

            window.history.pushState({view: type, styleId: styleId}, '');
        }

        if (type != currentType) {
            localStorage.setItem('listingType', type);
            document.querySelector('html').setAttribute('data-listing-type', type);
            $(document).trigger('listing-type.switch');
        }

        if (styleId) {
            otherContainer = $('[data-listing-item=' + type + '][data-id=' + styleId + ']');

            if (otherContainer.length) {
                $('body, html')
                    .animate({
                        scrollTop: otherContainer.offset().top - $('.header').outerHeight() - $('.listing-item__table-head--sticky').outerHeight()
                    }, 1000, 'easeOutQuint');
            }
        }

        if (type == 'technical') {
            $(window).trigger('resize');
        }
    }

$(document)
    .on('listing-type.switch', function(e) {

        // We need to refresh AOS to avoid some elements not appearing on switch

        AOS.refresh();

        $('.listing-switcher__link')
            .removeClass('listing-switcher__link--active')
            .filter('.listing-switcher__link[data-listing-switch="' + $('html').attr('data-listing-type') + '"]')
            .addClass('listing-switcher__link--active');
    });

$('.listing-switcher__link')
    .filter('.listing-switcher__link[data-listing-switch="' + $('html').attr('data-listing-type') + '"]')
    .addClass('listing-switcher__link--active');

$(document)
    .on('click', '.gallery__crosshair', function(e) {
        var crosshair = $(this),
            container = crosshair.parent(),
            activeClass = 'gallery__crosshair--active',
            active = crosshair.hasClass(activeClass);

        if (active) {
            crosshair.removeClass(activeClass);
            crosshair.trigger('crosshair.deactivated');
        } else {
            container
                .find('.' + activeClass)
                .removeClass(activeClass)
                .trigger('crosshair.deactivated');
            crosshair.addClass(activeClass);
            crosshair.trigger('crosshair.activated');
        }
    });

$(document)
    .on('click', '[data-add-to-basket]', function(e) {
        e.preventDefault();

        var trigger = $(this),
            target = '#add-to-basket-modal',
            options = {
                close: $('#js-add-to-basket-close'),
                add: $('#js-add-to-basket-add'),
                quantity: $('#js-add-to-basket-quantity')
            },
            content = $('.popup-content__container');

        if (typeof trigger.attr('data-disabled') !== 'undefined') {
            return false;
        }

        $.magnificPopup.open({
            type: 'inline',
            items: {src: target},
            mainClass: 'mfp--stock-levels',
            callbacks: {
                beforeOpen: function() {
                    var stock = JSON.parse(trigger.attr('data-stock'));

                    content.find('.product__name').text(trigger.attr('data-name'));
                    content.find('.product__sku').text(trigger.attr('data-sku'));
                    if (trigger.attr('data-finish')) {
                        content
                            .find('.product__finish')
                            .show()
                            .find('span:first')
                            .text(trigger.attr('data-finish'))
                    } else {
                        content
                            .find('.product__finish')
                            .hide();
                    }
                    content.find('.product__price').html(trigger.attr('data-price'));
                    content.find('.product__stock').html(getStockHtml(stock));

                    options.add.removeClass('button--disabled');
                    options.quantity.removeAttr('disabled');

                    if (stock.length) {
                        options.add.attr('href', trigger.attr('data-addUrl'));
                    } else {
                        options.add.addClass('button--disabled');
                        options.quantity.attr('disabled', true);
                    }
                },
                open: function() {
                    options.close.on('click', function(e) {
                        e.preventDefault();
                        $.magnificPopup.close();
                    });

                    options.add.on('click', function(e) {
                        e.preventDefault();

                        if (options.add.hasClass('button--disabled')) {
                            return;
                        }

                        $.ajax({
                            url: trigger.attr('data-addUrl') + '?qty=' + options.quantity.val()
                        }).done(function(data) {
                            $.magnificPopup.close();
                            if (data.payload.upsellUrl) {
                                window.location = data.payload.upsellUrl
                            } else {
                                $('#minibasket').html(data.payload.minibasket).addClass('mini-basket--show');
                                $('#minibasket-marker').addClass('mini-basket-marker--show');
                                $('#basket-qty-desktop').html(data.payload.item_count).removeClass('basket-link__text--hidden');
                                $('#basket-qty-mobile').html(data.payload.item_count).removeClass('basket-link__text--hidden');
                                TRACKING.trackAddProduct(data.payload);
                            }
                        });
                    });
                }
            }
        });
    });

$('.use-minibasket').on('submit', function(e) {
    e.preventDefault();

    var $addBtn = $('button', '.field--submit');
    $addBtn.addClass('button--disabled').attr('disabled', true);

    $.ajax({
        url: $(this).attr('action'),
        data: $(this).serializeArray()
    }).done(function(data) {
        if (data.payload.upsellUrl) {
            window.location = data.payload.upsellUrl
        } else {
            $('#minibasket').html(data.payload.minibasket).addClass('mini-basket--show');
            $('#minibasket-marker').addClass('mini-basket-marker--show');
            $('#basket-qty-desktop').html(data.payload.item_count).removeClass('basket-link__text--hidden');
            $('#basket-qty-mobile').html(data.payload.item_count).removeClass('basket-link__text--hidden');
            TRACKING.trackAddProduct(data.payload);
            $addBtn.removeClass('button--disabled').attr('disabled', false);
        }
    });

    return false;
});

$('#minibasket').click(function(e){
    e.stopPropagation();
});
$(document).on('click', function(e) {
    if ($('#minibasket').hasClass('mini-basket--show')) {
        console.log(e);
        $('#minibasket').removeClass('mini-basket--show');
        $('#minibasket-marker').removeClass('mini-basket-marker--show');
    }
});

// $('.js-scroll-table').scrollTable();

var decorativeXhr,
    technicalXhr,
    loading = '<div class="product-listing-item__loading js-product-listing-item__loading"><div class="product-listing-item__loading-image"><img src="/images/loading.gif" alt=""></div></div>';

// Very quickly done, will need a refactor
$('.js-styles__listing-items')
    .on('click', '.js-product-listing-item__variant', function(e) {
        e.preventDefault();
        var link = $(this),
            container = link.parents('.product-listing-item:first');

        if (link.hasClass('variants__item--active')) {
            window.location.href = link.attr('href');
            return;
        }

        container
            .find('.js-product-listing-item__variant')
            .removeClass('variants__item--active');

        link.addClass('variants__item--active');

        if (decorativeXhr && decorativeXhr.readyState != 4) {
            decorativeXhr.abort();
        }

        decorativeXhr = $.ajax({
            url: '/products/plp-data',
            data: {sku: link.data('sku')}
        });

        container.css('opacity', 0.4);
        container.find('.js-product-listing-item__overlay-block').show();
        container.find('.js-product-listing-item__badge').hide();
        container.find('.js-product-listing-item__image-wrap').append(loading);

        decorativeXhr
            .then(function(data) {
                if (data.message == 'Ok') {
                    container
                        .find('.js-product-listing-item__code')
                        .text(data.result.sku);

                    var $psProductCheckbox = container
                        .find('.js-ps-product-checkbox');

                    $psProductCheckbox.val(data.result.sku)
                        .prop('checked', false)
                        .trigger('change');

                    container
                        .find('.js-product-listing-item__finish')
                        .text(data.result.finish);

                    container
                        .find('.js-product-listing-item__link')
                        .attr('href', data.result.url);

                    container
                        .find('.js-product-listing-item__image')
                        .prepend(data.result.image)
                        .find('img:eq(1)')
                        .animate({
                            opacity: 0
                        }, 200, function() {
                            $(this).remove();
                        });

                    container
                        .find('.js-product-listing-item__title')
                        .text(data.result.title)

                    if (data.result.is_new === true) {
                        container
                            .find('.js-product-listing-item__badge')
                            .show();
                    }

                    container
                        .find('.product-listing-item__overlay-stock')
                        .html(data.result.stockOverlay);

                    if (data.result.price ) {
                        container
                            .find('.js-product-listing-item__price')
                            .text(data.result.price.replace('&pound;', '£'));
                    }

                    if (data.result.addUrl) {
                        container
                            .find('.js-product-listing-item__form')
                            .attr('action', data.result.addUrl);
                    }

                    var quickViewButton = container.find('[data-add-to-basket]:first');

                    if (quickViewButton && data.result.stock) {
                        if (data.result.stock.length) {
                            quickViewButton
                                .attr('data-name', data.result.title)
                                .attr('data-finish', data.result.finish)
                                .attr('data-sku', data.result.sku)
                                .attr('data-stock', JSON.stringify(data.result.stock))
                                .attr('data-price', data.result.price)
                                .attr('data-addUrl', data.result.addUrl)
                                .html('<span>' + globalTranslations.stockLevel + '</span>')
                                .removeClass('button--disabled')
                                .removeAttr('data-disabled');
                        } else {
                            quickViewButton
                                .removeAttr('data-name')
                                .removeAttr('data-finish')
                                .removeAttr('data-sku')
                                .removeAttr('data-stock')
                                .removeAttr('data-price')
                                .removeAttr('data-addUrl')
                                .html('<span>' + globalTranslations.outOfStock + '</span>')
                                .addClass('button--disabled')
                                .attr('data-disabled', true);
                        }
                    }

                    container.css('opacity', 1);
                    container.find('.js-product-listing-item__overlay-block').hide();
                    container.find('.js-product-listing-item__loading').remove();
                }
            });
    });

$('.js-styles__listing-items')
    .on('click', '[data-variant-row]', function(e) {
        // If clicked the checkbox carry on as normal
        if ($(e.target).closest('.product-listing-item__checkbox').length) {
            return;
        }

        e.preventDefault();

        var row = $(this),
            container = row.parents('.listing-item:first');

        if (row.hasClass('listing-item__table__data-row--active')) {
            window.location.href = row.data('url');
            return;
        }

        container
            .find('.listing-item__table__data-row--active')
            .removeClass('listing-item__table__data-row--active');

        row.addClass('listing-item__table__data-row--active');

        if (technicalXhr && technicalXhr.readyState != 4) {
            technicalXhr.abort();
        }

        technicalXhr = $.ajax({
            url: '/products/plp-data',
            data: {sku: row.data('sku')}
        });

        container.find('.listing-item__preview').css('opacity', 0.4);
        container.find('.listing-item__badge').hide();
        container.find('.listing-item__preview').append(loading);
        container.find('.listing-item__price').hide();

        technicalXhr
            .then(function(data) {
                if (data.message == 'Ok') {
                    container
                        .find('.listing-item__info a')
                        .attr('href', data.result.url);

                    container
                        .find('.listing-item__thumbnail')
                        .prepend(data.result.image)
                        .find('img:eq(1)')
                        .animate({
                            opacity: 0
                        }, 200, function() {
                            $(this).remove();
                        });

                    if (data.result.is_new === true) {
                        container
                            .find('.listing-item__badge')
                            .show();
                    }

                    container
                        .find('.listing-item__sku')
                        .text(data.result.sku);

                    container
                        .find('.listing-item__title a')
                        .text(data.result.title)
                        .attr('href', data.result.url);

                    if (data.result.price ){
                        container
                            .find('.listing-item__price')
                            .text(data.result.price.replace('&pound;', '£'))
                            .show();;
                    }

                    container.find('.listing-item__preview').css('opacity', 1);
                    container.find('.js-product-listing-item__loading').remove();
                }
            });
    });

var breakpoint = new Breakpoint();

$(document).on('click', '.js-product-listing-item__image-wrap', function(e) {
    if (breakpoint.matches('tablet')) {
        if (this.getAttribute('data-href')) {
            window.location = this.getAttribute('data-href');
        }
    }
});

$(document).on('click', '.js-ajax-reload', function(e) {
    e.preventDefault();
    var $link       = $(this);
    var results     = $link.data('results');
    var $results    = $(results);
    var additional  = $link.data('additional');
    var $additional = $(additional);

    history.pushState({}, null, $link.prop('href'));

    ajax_response++;

    $results.addClass('busy').addClass('ajax-results');

    var $ajax = $.ajax({
        type : 'get',
        url  : $link.prop('href'),
        data : {'request': ajax_response},
    });

    $ajax.done(function(response) {
        var $html = $('<html>');
        $html.html(response);
        var $response = $html.find(results);

        if ($response.length == 0) {
            console.log('Response not found');
        } else {
            if ($response.data('request') !== ajax_response) {
                // this is not the current request, ignore the response
                return;
            }
        }

        function finished($new) {
            $response = $new.find(results);
            $results.removeClass('busy');
            $results.html($response.html());

            if ($additional.length > 0) {
                var $response = $new.find(additional);
                for (var i = 0; i < $additional.length; i++) {
                    $additional.eq(i).html($response.eq(i).html());
                }
            }
        };

        var $images = $response.find('img');
        var loading = $images.length;

        if (loading == 0) {
            // no images, display the modal
            finished($html);
        } else {
            // wait until we've finished loading images before displaying
            // the modal so the height is correctly calculated.
            $images.on('load error', function() {
                loading--;
                if (loading <= 0) {
                    // finished loading images, display the modal
                    finished($html);
                }
            });
        }
    });

    $ajax.fail(function(data) {
        $results.removeClass('busy');
    });
});

$(document).on('click', '.js-selected', function(e) {
    e.preventDefault();
    var $link = $(this);
    var className = $link.data('selected');
    $link.addClass(className).siblings().removeClass(className);
});

window
    .onpopstate = function(e) {
        // Should never happen
        if (!e.state) {
            return;
        }

        if (e.state.view) {
            switchListingType(e.state.view, e.state.styleId, true);
        }

        if (!e.state.styleId) {
            $('body, html')
                .animate({scrollTop: 0}, 1000, 'easeOutQuint');
        }
    }

// This markup is also duplicated in partials/product/stock-info.html
var getStockHtml = function(stock) {
        var html = '';

        if (stock.length) {
            stock.forEach(function(item) {
                html += '<div class="product-stock__item';
                html += item.type == 'stock' ? ' product-stock__item--highlight' : '';
                html += '">';

                if (item.type == 'stock') {
                    html += '<div>' + item.quantity + ' ' + globalTranslations.inStock + '</div>';
                } else {
                    html += '<div>' + item.quantity + ' ' + globalTranslations.due + ' ' + item.pretty_date + '</div>';
                }

                html += '</div>';
            });
        } else {
            html += '<div class="product-stock__item">' + globalTranslations.outOfStock + '</div>';
        }

        return '<div class="product-info">' + html + '</div>';
    }

$(function() {
    var outerwraps = $('.tabs.detail__tabs'),
        innerwraps = $('.tabs__wrap');

    updateFadedEdges();

    $(window)
        .on('resize', function() {
            updateFadedEdges();
        })
    $(innerwraps)
        .on('scroll', function() {
            updateFadedEdges();
        })

    function updateFadedEdges() {
        $(outerwraps).each(function(i, wrap) {
            var class_left = $(wrap).hasClass('tabs--hightlight') ? 'tabs--highlighted--overflow-left' : 'tabs--overflow-left';
            var class_right = $(wrap).hasClass('tabs--hightlight') ? 'tabs--highlighted--overflow-right' : 'tabs--overflow-right';

            $(wrap).toggleClass(class_left, innerwraps.eq(i)[0].scrollLeft > 0);

            var rightOffset = $(wrap).innerWidth() - innerwraps.eq(i)[0].scrollWidth;
            $(wrap).toggleClass(class_right, innerwraps.eq(i)[0].scrollLeft < Math.abs(rightOffset));
        });
    }
});

$(function() {
    var slug = '/types';
    var viewingTypes = window.location.pathname.indexOf(slug) > -1;

    checkBrowseBy($('.js-product-browse').find('.product-browse__button').eq(viewingTypes ? 1 : 0));

    $('.js-product-browse').find('.product-browse__button').click(function(e) {
        e.preventDefault();
        checkBrowseBy($(this));
    });

    function checkBrowseBy(el) {
        visible = el.attr('data-browse-by');
        $('.js-product-browse').attr('data-browsing-by', visible);
    }

    $('#discontinued').on('change', function() {
        var parts = URN.parts(document.location.href);

        if (this.checked) {
            parts.querystring = URN.setQueryStringParam(parts.querystring, 'discontinued', '1');
            document.location = URN.build(parts);
        } else {
            parts.querystring = URN.setQueryStringParam(parts.querystring, 'discontinued', null);
            document.location = URN.build(parts);
        }
    });
});

// Open external links in a new tab
$(function() {
    $(document).on('click', 'a.external',function(e) {
        e.preventDefault();
        window.open($(this).attr('href'));
    });
});

String.prototype._moustache = function (data) {
    var s = this.toString();
    for (var i in data) {
        s = s.toString().replace('{' + i + '}', data[i]);
    }

    s = s.replace(/(\{.+?\})/g, '');

    return s;
};

$('.js-accordion__toggle').on('click.accordion-toggle', function() {
    if ($(this).parent().hasClass('accordion--disabled')) {
        return;
    }
    if ($(this).parent().hasClass('accordion--active')) {
        deactivate($(this).parent());
    } else {
        var group = $(this).parent().attr('data-accordion-group');
        if (group) {
            $('[data-accordion-group="' + group + '"]').each(function() {
                if ($(this).hasClass('accordion--active')) {
                    deactivate($(this));
                }
            });
        }
        activate($(this).parent());
    }

    // Activate the accordion
    function activate(el) {
        var wrapper = el.find('.accordion__wrapper');
        var content = el.find('.accordion__content');

        wrapper.css('maxHeight', content.height() + parseInt(content.css('paddingBottom')));
        el.addClass('accordion--active');
    }

    // Deactivate the accordion
    function deactivate(el) {
        var wrapper = el.find('.accordion__wrapper');

        wrapper.removeAttr('style');
        el.removeClass('accordion--active');
    }
});

function updateAccordion(el) {
    var wrapper = el.find('.accordion__wrapper');
    var content = el.find('.accordion__content');

    wrapper.css('maxHeight', content.height() + parseInt(content.css('paddingBottom')));
}

// If the accordion should be disabled for desktop, then we need to check the breakpoint on page load and on resize
var breakpoint = new Breakpoint();

if (breakpoint.matches('tablet')) {
    $('.accordion.accordion--tablet').removeClass('accordion--disabled');
} else {
    $('.accordion.accordion--tablet').addClass('accordion--disabled');
}

breakpoint.matchMedia('tablet').addListener(function(e) {
    if (e.matches) {
        $('.accordion.accordion--tablet').removeClass('accordion--disabled');
    } else {
        $('.accordion.accordion--tablet').addClass('accordion--disabled');
    }
});

$(document).on('click', 'a[href^="#"]', function(e) {
    var $this = $(this);
    var href = $this.attr('href');
    if (
        href != '#'
        && !$this.parents().hasClass('slick-slide')
        && !$this.hasClass('cookie-preferences__nav-link')
        && !$this.hasClass('site-switch')
    ) {
        e.preventDefault();
        scrollToEl($(href));
    }
});

function scrollToEl(el) {
    if (el.length) {
        var scrollDuration = 300;
        var offset = 0;

        var items = [
            $('#admin-bar'),
            $('#debug-bar'),
            $('.header'),
            $('.notice-bar')
        ];

        var scrollTop = Math.round(el.offset().top - offset);

        // if (window.matchMedia('(max-width: 550px)').matches) {
            for (var i = 0; i < items.length; i++) {
                if (items[i].length > 0) {
                    scrollTop -= items[i].outerHeight();
                }
            }
        // }

        $('html, body').animate({
            scrollTop: scrollTop
        }, {
            duration: scrollDuration
        });

        el.trigger('click');
    }
}

$('.js-listing-view').change(function() {
    $('html').attr('listing-technical-view', this.value);
});

$('.js-listing-view').trigger('change');

// Show the technical view key
$('.js-listing-show-key').on('click', function(e) {
    $.magnificPopup.open({
        type: 'inline',
        mainClass: 'mfp--key',
        closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
        items: {
            src: '.js-listing-key'
        },
        fixedContentPos: true
    });
});

$('.js-notice-show-info').on('click', function (e) {
    $.magnificPopup.open({
        type: 'inline',
        mainClass: 'mfp--notice mfp-fade',
        removalDelay: 200,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
        items: {
            src: '.js-notice'
        },
        fixedContentPos: true
    });
});

$('.js-bv-stock-overlay').on('click', function(e) {
    e.preventDefault();

    $.magnificPopup.open({
        type: 'inline',
        mainClass: 'mfp--pdp-overlay mfp-fade',
        removalDelay: 200,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
        items: {
            src: '.js-bv-overlay'
        },
        fixedContentPos: true
    })
})

$('.js-no-product-notice').on('click', function(e) {
    e.preventDefault();

    var trigger = $(this),
        options = {
            close: $('.js-plp-overlay-close'),
            viewProfessional: $('.js-plp-overlay-view-professional'),
            title: $('.js-plp-overlay-title')
        };

    $.magnificPopup.open({
        type: 'inline',
        mainClass: 'mfp--plp-overlay mfp-fade',
        removalDelay: 200,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
        items: {
            src: '.js-plp-overlay'
        },
        fixedContentPos: true,
        callbacks: {
            beforeOpen: function () {
                options.close.on('click', function (e) {
                    e.preventDefault();
                    $.magnificPopup.close();
                });

                options.viewProfessional.attr('href', trigger.attr('href'));
                options.title.html(trigger.data('overlay-title'));
            }
        }
    })
})

window.realignListingAction = function() {
    $('.js-listing-item-group').each(function() {
        var action = $('.js-listing-item__action[data-id="' + $(this).attr('data-id') + '"]');
        if (action) {
            var height = $(this).height();
            var actionHeight = action.height();
            var diff = height - actionHeight;
            action.css({
                paddingTop: diff / 2,
                paddingBottom: diff / 2
            });
        }
    });
}

window.checkScrollbarWidth = function() {
    var scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');
}

var resize;

$(window).on('resize', function(e) {
    clearTimeout(resize);
    resize = setTimeout(function() {
        window.realignListingAction();
        window.checkScrollbarWidth();
    }, 100);
});

$(window).on('load', function(e) {
    window.realignListingAction();
    window.checkScrollbarWidth();
});

// $('.site-switch').magnificPopup({
//     type: 'inline',
//     mainClass: 'mfp--site-switch mfp-fade',
//     removalDelay: 200,
//     showCloseBtn: false
// });
//
// $(document).on('click', '.js-site-switch-close', function(e) {
//     $.magnificPopup.close();
// });

$(window).on('load', function() {
     resizeProjectBlock();

    $(window).on('resize', function() {
        resizeProjectBlock();
    });

    function resizeProjectBlock() {
        $('.block__wrap--project').each(function() {
            var $header = $(this).find('.block__image--project'),
                $content = $(this).find('.block-content--project'),
                heightDiff = $content.height() - $header.height();

            var margins = parseInt($content.css('margin-top')) +
                parseInt($content.css('margin-bottom')) -
                parseInt($header.css('margin-bottom'));

            $header.css('margin-bottom', heightDiff + margins);
        });
    }
});

(function () {
    const accordionHeaders = document.querySelectorAll('[data-accordion-header]');
    const mobileOnlyAccordion = document.querySelector('[data-mobile-accordion]');

    accordionHeaders.forEach(accordionHeader => {

        let target = accordionHeader.parentElement.nextElementSibling;

        if (mobileOnlyAccordion) {
            if (window.matchMedia('(max-width: 1023px)').matches) {
                target.hidden = true;
            } else {
                target.hidden = false;
            }
        } else {
            target.hidden = true;
        }

        accordionHeader.addEventListener('click', () => {

            let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
            accordionHeader.setAttribute('aria-expanded', !expanded);
            target.hidden = expanded;
        })

        window.addEventListener('resize', () => {
            if (mobileOnlyAccordion) {
                if (window.matchMedia('(max-width: 1023px)').matches && mobileOnlyAccordion) {
                    target.hidden = true;
                } else {
                    target.hidden = false;
                }
            }
        })
    })
})();

$('.js-compatible-item')
    .on('click', '.js-compatible-item__variant', function(e) {
        e.preventDefault();
        var $variant = $(this),
            $container = $variant.parents('.js-compatible-item');

        if ($variant.hasClass('variants__item--active')) {
            window.location.href = $variant.attr('href');
            return;
        }

        $container
            .find('.js-compatible-item__variant')
            .removeClass('variants__item--active');

        $variant.addClass('variants__item--active');

        if (decorativeXhr && decorativeXhr.readyState != 4) {
            decorativeXhr.abort();
        }

        decorativeXhr = $.ajax({
            url: '/products/plp-data',
            data: {sku: $variant.data('sku')}
        });

        $container.css('opacity', 0.4);

        decorativeXhr
            .then(function(data) {
                if (data.message == 'Ok') {
                    $container
                        .find('.compatible-item__meta')
                        .text(data.result.sku);

                    $container
                        .find('.js-compatible-item__link')
                        .attr('href', data.result.url);

                    $container
                        .find('.compatible-item__image > figure')
                        .prepend(data.result.image)
                        .find('img:eq(1)')
                        .animate({
                            opacity: 0
                        }, 200, function() {
                            $(this).remove();
                        });

                    $container
                        .find('.compatible-item__title')
                        .text(data.result.title)

                    if (data.result.price ) {
                        $container
                            .find('.compatible-item__price')
                            .text(data.result.price.replace('&pound;', '£'));
                    }

                    $container.css('opacity', 1);
                }
            });
    });

/*
* These need to be hooked up correctly for the order import
*/

window.fileSelected = function(ev, button, value) {
    if  (!value) {
        button.text('choose file');
    } else {
        $('.field--submit').addClass('field--submit--active');
        var filename = value.split('\\').pop();
        button.text(filename);
    }
}

window.dropHandler = function(ev) {
    console.log('File(s) dropped');

    var input = document.querySelector('#partner_import_uploaded_file');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    input.files = ev.dataTransfer.files;
    $(input).change();
}

window.dragOverHandler = function(ev) {
  console.log('File(s) in drop zone');

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

/**
* We need to remove the local storage attribute here if we navigate away from the import page
* See open-accordion.js
*/

if (!window.location.href.includes('/imports/review')) {
    localStorage.removeItem('accordion');
}

// Add PDP configuration pop

(() => {
    const configureButton = document.querySelector('[data-configure]');

    if (!configureButton) {
        return;
    }

    configureButton.addEventListener('click', () => {
        $.magnificPopup.open({
            type: 'inline',
            mainClass: 'mfp--pdp-configure-popup mfp-fade',
            removalDelay: 200,
            closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="Close">' + svg.render('close') + '</button>',
            items: {
                src: '.js-pdp-configure'
            },
            fixedContentPos: true,
            callbacks: {
                open: function() {
                    const scrollContainer = this.wrap[0];
                    
                    if (scrollContainer.scrollHeight > scrollContainer.clientHeight) {
                        scrollContainer.classList.add('mfp--has-scroll');
                    }
                }
            }
        });  
    });
})()
